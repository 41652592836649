










































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator'
import { Checkbox, Popover } from 'element-ui'
Vue.use(Checkbox)
Vue.use(Popover)

@Component
export default class Table extends Vue {
  // 表格行高度
  @Prop({ default: 54 }) private height!: number
  // 表格内容区域100vh需要减去的高度
  @Prop({ default: 366 }) private contHeight!: number
  @Prop({ default: true }) private isNeedcontHeight!: boolean
  @Prop({ default: true }) private isNeedId!: boolean
  @Prop() private pageInfo!: any
  @Prop({ default: false }) private isChoose!: boolean
  @Prop({ default: false }) private loading!: boolean
  @Prop() private header!: object[]
  @Prop() private content!: object[]
  // 表格的唯一标志字段
  @Prop() private keyField!: string
  // 是否需要表头
  @Prop({ default: true }) private isNeedHeader!: boolean
  @PropSync('clearChecked', { type: Boolean, default: false })
  isClearChecked!: any

  allChecked = false
  checkedList: any = []
  // 设置行高的样式
  setHeight = `height: ${this.height}px;`
  // 悬浮至某一行时的样式
  active = ''
  // 悬浮至哪一行
  currentIndex = -1

  @Watch('content')
  getcontent() {
    this.setSingleCheckedState()
    this.setAllCheckedState()
  }

  @Watch('checkedList')
  getcheckedList() {
    this.setAllCheckedState()
  }

  @Watch('isClearChecked')
  getisClearChecked(val: any) {
    if (val) {
      this.checkedList = []
      this.setSingleCheckedState()
      this.setAllCheckedState()
      this.$emit('getChecked', this.checkedList)
    }
  }

  mouseOver(index: number) {
    this.currentIndex = index
    this.active =
      'background: rgba(243, 244, 245, 0.6);box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.07);' +
      this.setHeight
  }

  mouseLeave() {
    this.currentIndex = -1
    this.active = ''
  }

  // 全选按钮状态发生改变
  allCheckedChange() {
    // if (this.isClearChecked) {
    //   this.checkedList = []
    // }
    if (this.allChecked) {
      this.content.map((v: any) => {
        v.isChecked = true
        const index = this.checkedList.findIndex(
          (item: any) => item[this.keyField] === v[this.keyField]
        )
        if (index === -1) {
          this.checkedList.push(v)
        }
      })
    } else {
      this.content.map((v: any) => {
        v.isChecked = false
        this.checkedList.splice(
          this.checkedList.findIndex(
            (item: any) => item[this.keyField] === v[this.keyField]
          ),
          1
        )
      })
    }
    this.$emit('getChecked', this.checkedList)
  }
  // 单个选择按钮状态发生改变
  singleCheckedChange(isChecked: boolean, item: any, index: number) {
    if (isChecked) {
      this.content.map((v: any, i: number) => {
        if (i === index) {
          v.isChecked = true
          const index = this.checkedList.findIndex(
            (item: any) => item[this.keyField] === v[this.keyField]
          )
          if (index === -1) {
            this.checkedList.push(v)
          }
        }
      })
    } else {
      this.content.map((v: any, i: number) => {
        if (i === index) {
          v.isChecked = false
          this.checkedList.splice(
            this.checkedList.findIndex(
              (item: any) => item[this.keyField] === v[this.keyField]
            ),
            1
          )
        }
      })
    }
    this.$emit('getChecked', this.checkedList)
  }
  // 重置全选状态
  setAllCheckedState() {
    const index = this.content.findIndex((v: any) => v.isChecked === false)
    if (index === -1) {
      this.allChecked = true
    } else {
      this.allChecked = false
    }
  }
  // 重置单个选择状态
  setSingleCheckedState() {
    this.content.map((v: any) => {
      const index = this.checkedList.findIndex(
        (item: any) => item[this.keyField] === v[this.keyField]
      )
      if (index !== -1) {
        v.isChecked = true
      } else {
        v.isChecked = false
      }
    })
  }
}
